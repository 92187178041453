.root {
    display: flex;
    overflow: scroll;

    -ms-overflow-style: none;
    scrollbar-width: none;
}

.root::-webkit-scrollbar {
    display: none;
}

.header {
    position: relative;
    min-height: 52px;
    margin-right: 20px;
}

.elementWrapper {
    display: flex;
}

.element {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 12px;
    width: fit-content;
    margin-right: 5px;
}

.comboElement {
    padding: 3px 0px;
    width: max-content;
}

.inlineComboElement {
    padding: 0 5px;
    width: auto;
}

.inlineHeaderImg {
    margin: auto;
}

.comboHeaderElement {
    padding: 5px 2px;
}

.headerElement {
    display: flex;
    align-items: center;
    border-radius: 12px;
    height: 100%;
    min-width: 105px;
    @media (min-width: 1280px) {
        min-width: 154px;
    }
}

.headerBodyElement {
    --color: #f3f6f9;

    &::after {
        content: "";
        position: absolute;
        top: 50%;
        width: 31px;
        height: 31px;
        right: -15px;
        background-color: var(--color);
        border-radius: 0 6px 0 0;
        transform: translateY(-50%) rotate(45deg) skew(10deg, 10deg);
    }
    &::before {
        content: "";
        position: absolute;
        background-color: var(--color);
        top: 50%;
        transform: translateY(-50%);
        width: 20px;
        height: 52px;
        right: 0px;
        border-radius: 2px;
    }
}

.headerBodyComboElement {
    --color: #f2f0ff;
}

.imgRoot {
    display: flex;
}

.img {
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #f8f9fb;
    margin: 5px 0 5px 5px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    flex-grow: 0;
}

.imgInline {
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #fff;
    margin: 5px 0 5px 5px;
    width: 40px;
    height: 40px;
    flex-shrink: 0;
    flex-grow: 0;
}

.text {
    margin-right: 5px;
    margin-left: 5px;
    min-width: 140px;
}

.headerText {
    margin-left: 10px;
    position: relative;
    z-index: 1;
}

.rootButton {
    cursor: pointer;
    position: absolute;
}

.button {
    display: flex;
    align-items: center;
    height: 34px;
    position: relative;
    border-radius: 6px;
    background-color: #f3f6f9;
    cursor: pointer;
    width: max-content;
}

.btnText {
    display: flex;
    flex-direction: column;
    margin: 5px 0 5px 0;
}

.imgBtn {
    margin: 5px;
    position: relative;
    cursor: pointer;
}

.iconInfotBtn {
    margin-top: 5px;
}

.tooltipRootWidth {
    width: 268px;
}

.tooltipTopRoot {
    padding: 5px;
    background: #ffffff;
    border-radius: 12px;
    position: absolute;
    z-index: 2;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.tooltipTopRoot::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 1px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 20px solid white;
    top: -18px;
    left: calc(50% - 20px);
    z-index: 2;
}

.tooltipLeftRoot {
    padding: 5px;
    background: #ffffff;
    border-radius: 12px;
    position: absolute;
    z-index: 2;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.tooltipLeftRoot::before {
    content: "";
    position: absolute;
    width: 1px;
    height: 1px;
    border-top: 10px solid transparent;
    border-right: 20px solid white;
    border-bottom: 10px solid transparent;
    top: calc(50% - 8px);
    left: -20px;
    z-index: 2;
}

.tooltipPadding {
    padding-left: 10px;
}

.elWrapper {
    padding-bottom: 5px;
}
